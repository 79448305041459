<template>
  <div>
    <CHeader />
    <CMenumobile
      v-if="
        this.$vuetify.breakpoint.name == 'sm' ||
        this.$vuetify.breakpoint.name == 'xs'
      "
    />

    <v-main class="my-5 px-3">
      <v-row>
        <v-col
          cols="3"
          style="position: sticky; top: 60px; align-self: start"
          v-if="
            this.$vuetify.breakpoint.name == 'md' ||
            this.$vuetify.breakpoint.name == 'lg' ||
            this.$vuetify.breakpoint.name == 'xl'
          "
        >
          <CMenu />
        </v-col>
        <v-col cols="12" md="8">
          <router-view />
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import CHeader from "@/layout/header";
import CMenu from "@/layout/menu";
import CMenumobile from "@/layout/menu-mobile";

export default {
  components: {
    CHeader,
    CMenu,
    CMenumobile,
  },

  data: () => ({}),

  mounted() {
    if (this.$route.path === "/") {
      this.$router.push("/home").catch(() => {});
    }
  },
};
</script>
