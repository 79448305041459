<template>
  <div>
    <v-card height="85vh" tile flat color="#eeeeee">
      <v-list shaped color="#eeeeee">
        <v-list-item
          to="/home"
          active-class="utama--text text--accent-4"
          class="pl-10"
          v-if="SECURITY"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          to="/security"
          active-class="utama--text text--accent-4"
          class="pl-10"
          v-if="SECURITY"
        >
          <v-list-item-icon>
            <v-icon>mdi-shield-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Security</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          to="/service"
          active-class="utama--text text--accent-4"
          class="pl-10"
          v-if="SERVICE"
        >
          <v-list-item-icon>
            <v-icon>mdi-progress-wrench</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Service</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-3"></v-divider>

        <v-list-item
          to="/user"
          active-class="utama--text text--accent-4"
          class="pl-10"
          v-if="SERVICE"
        >
          <v-list-item-icon>
            <v-icon color="success">mdi-account-badge</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Active User</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.cek()
  },

  data: () => ({
    session: "",

    SECURITY: false,
    SERVICE: false,
  }),

  methods: {
    cek() {
      let cek = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      let arr = cek.authorities;
      this.SECURITY = arr.some((item) => item === "SECURITY");
      this.SERVICE = arr.some((item) => item === "SERVICE");
    },
  },
};
</script>