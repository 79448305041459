var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CHeader'),(
      this.$vuetify.breakpoint.name == 'sm' ||
      this.$vuetify.breakpoint.name == 'xs'
    )?_c('CMenumobile'):_vm._e(),_c('v-main',{staticClass:"my-5 px-3"},[_c('v-row',[(
          this.$vuetify.breakpoint.name == 'md' ||
          this.$vuetify.breakpoint.name == 'lg' ||
          this.$vuetify.breakpoint.name == 'xl'
        )?_c('v-col',{staticStyle:{"position":"sticky","top":"60px","align-self":"start"},attrs:{"cols":"3"}},[_c('CMenu')],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }