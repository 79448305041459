<template>
  <v-navigation-drawer app v-model="drawer" temporary>
    <v-list-item class="py-1">
      <v-list-item-content>
        <v-list-item-title class="text-h6 utama--text">
          Menu
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list dense>
      <v-list-item :to="'/home'" color="utama" v-if="SECURITY">
        <v-list-item-icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>

      <v-list-item :to="'/security'" color="utama" v-if="SECURITY">
        <v-list-item-icon>
          <v-icon>mdi-shield-lock</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Security</v-list-item-title>
      </v-list-item>

      <v-list-item :to="'/service'" color="utama" v-if="SERVICE">
        <v-list-item-icon>
          <v-icon>mdi-progress-wrench</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Service</v-list-item-title>
      </v-list-item>

      <v-list-item :to="'/user'" color="utama" v-if="SERVICE">
        <v-list-item-icon>
          <v-icon color="success">mdi-account-badge</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Active User</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import drawerState from "@/store/drawer";

export default {
  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.cek();
  },

  computed: {
    drawer: {
      get() {
        return drawerState.state.drawer;
      },
      set(value) {
        drawerState.commit("toggle", value);
      },
    },
  },

  data: () => ({
    session: "",

    SECURITY: false,
    SERVICE: false,
  }),

  methods: {
    cek() {
      let cek = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      let arr = cek.authorities;
      this.SECURITY = arr.some((item) => item === "SECURITY");
      this.SERVICE = arr.some((item) => item === "SERVICE");
    },
  },
};
</script>