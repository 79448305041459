import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookie from '@/helper/cookie.js'

import main from '../layout/main.vue'

Vue.use(VueRouter)

const routes = [{
    // catch all 404 - define at the very end
    path: "*",
    name: 'notfound',
    component: () => import( /* webpackChunkName: "notfound" */ '../layout/notfound.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(
      /* webpackChunkName: "cekauth" */
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      "../views/Auth"
    ),
  },
  {
    path: '/login',
    component: () => import(
      /* webpackChunkName: "login" */
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      "../views/Login"
    ),
  },
  {
    path: '/resetpassword',
    component: () => import(
      /* webpackChunkName: "resetpass" */
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      "../views/Resetpassword"
    ),
  },

  {
    path: '/',
    name: 'home',
    component: main,
    meta: {
      requiresAuth: true
    },
    children: [{
        path: '/home',
        meta: {
          title: 'SECURITY',
          SECURITY: true,
        },
        component: () => import(
          /* webpackChunkName: "home" */
          /* webpackPrefetch: true */
          /* webpackPreload: true */
          '../views/Home.vue'
        )
      },
      {
        path: '/security',
        meta: {
          title: 'SECURITY',
          SECURITY: true,
        },
        component: () => import(
          /* webpackChunkName: "security" */
          /* webpackPrefetch: true */
          /* webpackPreload: true */
          '../views/Security.vue'
        )
      },
      {
        path: '/security/:id',
        meta: {
          title: 'SECURITY',
          SECURITY: true,
        },
        component: () => import(
          /* webpackChunkName: "securityDetail" */
          /* webpackPrefetch: true */
          /* webpackPreload: true */
          '../components/security/user/detail.vue'
        )
      },
      {
        path: '/service',
        meta: {
          title: 'SERVICE',
          SERVICE: true,
        },
        component: () => import(
          /* webpackChunkName: "service" */
          /* webpackPrefetch: true */
          /* webpackPreload: true */
          '../views/Service.vue'
        )
      },
      {
        path: '/user',
        meta: {
          title: 'SERVICE',
          SERVICE: true,
        },
        component: () => import(
          /* webpackChunkName: "user" */
          /* webpackPrefetch: true */
          /* webpackPreload: true */
          '../views/User.vue'
        )
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  var myCookie = Cookie.get('myCookie');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!myCookie) {
      next({
        path: '/auth',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      const session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      let arr = session.authorities
      if (to.matched.some(record => record.meta.early_check)) {
        if (arr.length > 0) {
          next()
        } else {
          next({
            path: '/auth',
            params: {
              nextUrl: to.fullPath
            }
          })
        }
      } else if (to.matched.some(record => record.meta.SECURITY)) {
        if (arr.some((item) => item === 'SECURITY')) {
          next()
        } else {
          next({
            path: '/auth',
            params: {
              nextUrl: to.fullPath
            }
          })
        }
      } else if (to.matched.some(record => record.meta.SERVICE)) {
        if (arr.some((item) => item === 'SERVICE')) {
          next()
        } else {
          next({
            path: '/auth',
            params: {
              nextUrl: to.fullPath
            }
          })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router